import debounce from 'lodash.debounce';

// A simple back to Top button written in pure Javascript (i.e. No Jquery)
function ready(fn) {
    if (
        document.attachEvent
            ? document.readyState === 'complete'
            : document.readyState !== 'loading'
    ) {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(toTop);

function toTop() {
    var top = document.getElementById('scroll-top');

    top.addEventListener('click', function() {
        window.scrollTo(0, 0);
    });
}

var runOnScroll = function(evt) {
    var el = document.getElementById('scroll-top');
    var posY = evt.pageY;
    var className = 'is-visible';

    if (posY >= 500) {
        if (el.classList) {
            el.classList.add('is-visible');
        } else {
            el.className += ' is-visible';
        }
    } else {
        if (el.classList) {
            el.classList.remove(className);
        } else {
            el.className = el.className.replace(
                new RegExp(
                    '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
                    'gi'
                ),
                ' '
            );
        }
    }
};

var debounced_version = debounce(runOnScroll, 100);

window.addEventListener('scroll', debounced_version);
